const MAX_NUMBER = 99

export const getFixedNotificationsUnread = ({
  notificationsUnread,
}: {
  notificationsUnread: number
}) => {
  const fixedNumber = Math.min(notificationsUnread, MAX_NUMBER)

  return fixedNumber === MAX_NUMBER ? `${fixedNumber}+` : fixedNumber
}
