import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FeedSvg from '@/presentation/assets/img/icon/feed-icon.svg'
import NotificationSvg from '@/presentation/assets/img/icon/notifications-icon.svg'
import { IIterativeItemComponentProps } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent.types'
import { getFixedNotificationsUnread } from '@/presentation/components/Utils/GetFixedNotificationsUnread/GetFixedNotificationsUnreadUtils'
import { useIsPathMatched } from '@/presentation/hooks/UseIsPathMatched/UseIsPathMatched'
import { useSelector } from '@codebox-team/redux'
import { selectNotificationsUnread } from '@move-share-celebrate/core'

export const useFooterNavigationComponentRules = () => {
  const history = useHistory()
  const { isPathMatched } = useIsPathMatched()

  const notificationsUnread = useSelector(selectNotificationsUnread)

  const footerItems: IIterativeItemComponentProps[] = useMemo(
    () => [
      {
        svgIcon: FeedSvg,
        onClick: () => history.push('/'),
        text: translate.t('menu.items.feed'),
        isSelected: isPathMatched('/'),
      },
      {
        svgIcon: 'add',
        text: translate.t('menu.items.new_post'),
        onClick: () => history.push('/new-post'),
        isSelected: isPathMatched('/new-post'),
      },
      {
        svgIcon: NotificationSvg,
        text: translate.t('menu.items.notifications'),
        onClick: () => history.push('/notifications'),
        badgeContent: getFixedNotificationsUnread({ notificationsUnread }),
        isSelected: isPathMatched('/notifications'),
      },
    ],
    [history, isPathMatched, notificationsUnread],
  )

  return { footerItems }
}
