import { CardComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledCardComponent = StyleSheet.create(CardComponent)({
  position: 'sticky',
  top: 'calc(64px + 24px)',
  padding: '24px 0 16px 0',
  height: 'fit-content',
  maxHeight: '80vh',
  overflowY: 'auto',
})
