import React from 'react'

import { PoweredByComponent } from '@/presentation/components/Data/PoweredBy/PoweredByComponent'
import { ProfileComponent } from '@/presentation/components/Data/Profile/ProfileComponent'
import { useDrawerNavigationComponentRules } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent.rules'
import { StyledIconButtonComponent } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent.styles'
import { IDrawerNavigationComponent } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent.types'
import { IterativeItemComponent } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent'
import { CommunityListDialog } from '@/presentation/dialogs/CommunityList/CommunityListDialog'
import { useSelector } from '@codebox-team/redux'
import {
  DrawerComponent,
  DividerComponent,
  BoxComponent,
  IconComponent,
} from '@codebox-team/web/components'
import { selectUserDetails } from '@move-share-celebrate/core'

export const DrawerNavigationComponent: React.FC<
  IDrawerNavigationComponent
> = ({ notificationsUnread, isOpen, setIsOpen }) => {
  const { drawerItems, isCommunityListOpen, handleCloseCommunityDialog } =
    useDrawerNavigationComponentRules({
      notificationsUnread,
      isOpen,
      setIsOpen,
    })

  const userDetails = useSelector(selectUserDetails)

  return (
    <React.Fragment>
      <DrawerComponent
        data-testid="Drawer component"
        variant="temporary"
        onClose={() => setIsOpen(false)}
        open={isOpen}>
        <StyledIconButtonComponent onClick={() => setIsOpen(false)}>
          <IconComponent icon="clear" />
        </StyledIconButtonComponent>

        <BoxComponent
          display="flex"
          flexDirection="column"
          padding="24px"
          height="100%"
          justifyContent="space-between">
          <BoxComponent
            display="flex"
            flexDirection="column"
            width="250px"
            gap="16px">
            <ProfileComponent
              photoUri={userDetails?.photoUrl}
              name={userDetails?.name}
              jobTitle={userDetails?.jobTitle}
              profileOrientation="row"
            />

            <BoxComponent>
              {drawerItems.map((item, index) => (
                <BoxComponent
                  key={`drawer_item_${item.text}`}
                  display="flex"
                  flexDirection="column"
                  marginTop="16px"
                  alignItems="flex-start">
                  <IterativeItemComponent
                    testID={`Drawer component item ${index}`}
                    itemOrientation="row"
                    {...item}
                  />
                  <DividerComponent sx={{ width: '100%', marginTop: '16px' }} />
                </BoxComponent>
              ))}
            </BoxComponent>
          </BoxComponent>
          <BoxComponent textAlign="center">
            <PoweredByComponent />
          </BoxComponent>
        </BoxComponent>
      </DrawerComponent>
      {isCommunityListOpen && (
        <CommunityListDialog
          isOpen={isCommunityListOpen}
          onClose={handleCloseCommunityDialog}
        />
      )}
    </React.Fragment>
  )
}
