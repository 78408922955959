import React from 'react'

import VideoPlayIcon from '@/presentation/assets/img/icon/video-play-icon.svg'
import { useFileThumbnailComponentRules } from '@/presentation/components/Data/FileViewer/FileThumbnail/FileThumbnailComponent.rules'
import { VideoOverlayWrapper } from '@/presentation/components/Data/FileViewer/FileThumbnail/FileThumbnailComponent.styles'
import { IFileThumbnailComponentProps } from '@/presentation/components/Data/FileViewer/FileThumbnail/FileThumbnailComponent.types'
import { SkeletonComponent } from '@codebox-team/web/components'

export const FileThumbnailComponent: React.FC<IFileThumbnailComponentProps> = ({
  media: { key, mimeType, thumbnailUrl, url },
  isExtraMediasCounting,
}) => {
  const { thumbnailStyle, ref, isLoaded, isLazyLoad, setIsLoaded } =
    useFileThumbnailComponentRules()

  return (
    <React.Fragment>
      {!isLoaded && (
        <SkeletonComponent
          sx={{ position: 'absolute', height: '100%' }}
          variant="rectangular"
        />
      )}

      {isLoaded && !!mimeType.includes('video') && !isExtraMediasCounting && (
        <VideoOverlayWrapper>
          <VideoPlayIcon />
        </VideoOverlayWrapper>
      )}

      {!!thumbnailUrl ? (
        <img
          style={thumbnailStyle}
          src={thumbnailUrl}
          ref={ref}
          onLoad={() => setIsLoaded(true)}
          alt={key}
          loading={isLazyLoad ? 'lazy' : 'eager'}
        />
      ) : (
        <React.Fragment>
          {!!mimeType.includes('video') && (
            <video ref={ref} style={thumbnailStyle} preload="none">
              <source
                src={url}
                type={mimeType}
                onLoad={() => setIsLoaded(true)}
              />
            </video>
          )}

          {!!mimeType.includes('image') && (
            <img
              style={thumbnailStyle}
              src={url}
              ref={ref}
              alt={key}
              loading={isLazyLoad ? 'lazy' : 'eager'}
              onLoad={() => setIsLoaded(true)}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
