import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { persistor, store } from '@/application/store/StoreConfig'
import { ThemeGlobalStyles } from '@/application/theme/ThemeGlobalStyles'
import { themeOverrides } from '@/application/theme/variables'
import { FeedProvider } from '@/presentation/components/Providers/Feed/FeedProvider'
import { NotificationsUnreadProvider } from '@/presentation/components/Providers/NotificationsUnread/NotificationsUnreadProvider'
import { PostProvider } from '@/presentation/components/Providers/Post/PostProvider'
import { AppStoreProvider } from '@codebox-team/redux'
import { Brands } from '@codebox-team/styleguide'
import { ThemeProvider } from '@codebox-team/web/components'
import { configureTheme } from '@codebox-team/web/utils'
import { EngagementProvider } from '@move-share-celebrate/core'

export const ApplicationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const theme = configureTheme({
    type: 'light',
    brandType: Brands.AirLiquide,
    overrides: themeOverrides,
  })

  return (
    <AppStoreProvider store={store} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Switch>
            <FeedProvider>
              <NotificationsUnreadProvider>
                <EngagementProvider>
                  <PostProvider>{children}</PostProvider>
                </EngagementProvider>
              </NotificationsUnreadProvider>
            </FeedProvider>
          </Switch>
        </BrowserRouter>
        <ThemeGlobalStyles />
      </ThemeProvider>
    </AppStoreProvider>
  )
}
