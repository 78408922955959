import { getCurrentLocale } from '@/application/utils/Translate/TranslateUtil'
import {
  Store,
  applyMiddleware,
  composeWithDevTools,
  createStore,
  persistReducer,
  persistStore,
  Action,
} from '@codebox-team/redux'
import { createSagaMiddleware } from '@codebox-team/redux-saga'
import {
  tokenConfig,
  ReducerConfig,
  SagaConfig,
  TStoreState,
  UserDetailsCreators,
  HttpClientAuthorizedConfig,
  HttpClientEncryptedConfig,
  makeAuthenticationRefreshTokenFactory,
  makePlatformSaveAccountAccessFactory,
} from '@move-share-celebrate/core'

import storage from 'redux-persist/lib/storage'

export const PersistedReducer = persistReducer(
  {
    key: '@move-share-celebrate',
    storage,
    whitelist: ['user'],
  },
  ReducerConfig,
)

export const SagaMiddleware = createSagaMiddleware()
export const middlewares = [SagaMiddleware]

export const RootReducer = (state: TStoreState, action: Action) => {
  if (action.type === UserDetailsCreators.EActionTypes.RESPONSE_CLEAR) {
    return PersistedReducer(
      {
        _persist: state._persist,
      } as TStoreState,
      action,
    )
  }

  return PersistedReducer(state, action)
}

export const store: Store<TStoreState> = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
)

HttpClientAuthorizedConfig.addLocale(getCurrentLocale())
HttpClientAuthorizedConfig.addPlatform('WEB')
HttpClientAuthorizedConfig.addTokenService(tokenConfig(store))
HttpClientAuthorizedConfig.addTokenRefreshService(
  makeAuthenticationRefreshTokenFactory(),
)
HttpClientAuthorizedConfig.addPlatformSaveAccountAccessService(
  makePlatformSaveAccountAccessFactory(),
)

HttpClientEncryptedConfig.addLocale(getCurrentLocale())
HttpClientEncryptedConfig.addPlatform('WEB')
HttpClientEncryptedConfig.addTokenService(tokenConfig(store))
HttpClientEncryptedConfig.addTokenRefreshService(
  makeAuthenticationRefreshTokenFactory(),
)
HttpClientEncryptedConfig.addPlatformSaveAccountAccessService(
  makePlatformSaveAccountAccessFactory(),
)

export const persistor = persistStore(store)
SagaMiddleware.run(SagaConfig)
