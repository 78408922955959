import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { TextComponent } from '@codebox-team/web/components'
import {
  useContentExpanded,
  useMentionsHandlers,
  NAME_MENTION_REGEX,
  WEBSITE_REGEX,
  CONTENT_LIMIT_CHARS,
  sanitizeString,
  parseMentions,
} from '@move-share-celebrate/core'

export const useContent = (props: {
  content: string
  postKey?: string
  contentLimitChars?: number
}) => {
  const content = props?.content || ''
  const contentLimitChars = props?.contentLimitChars || CONTENT_LIMIT_CHARS

  const history = useHistory()

  const { getOverlappingMentionAmount } = useMentionsHandlers({
    contentLimitChars,
  })

  const { mentions, parsedContent } = parseMentions(content)
  const amountOverlap = getOverlappingMentionAmount(parsedContent)
  const adjustedLimit = contentLimitChars + amountOverlap

  const sanitizedContent = sanitizeString(parsedContent)

  const handleMentions = useCallback(
    (content: (string | React.JSX.Element)[]) => {
      return content.map(value => {
        if (typeof value === 'string') {
          const slices = value.split(NAME_MENTION_REGEX)

          return slices.map((slice, index) => {
            if (NAME_MENTION_REGEX.test(slice) && mentions[slice]) {
              const mention = slice.replace('$', '')
              return (
                <TextComponent
                  key={`mention_${mentions[slice]}_${index}`}
                  component="span"
                  color="primary.light"
                  title={mention}
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => history.push(`/profile/${mentions[slice]}`)}>
                  {mention}
                </TextComponent>
              )
            }

            return (
              <TextComponent
                key={`common_content_${slice}_${index}`}
                component="span">
                {slice}
              </TextComponent>
            )
          })
        }

        return value
      })
    },
    [history, mentions],
  )

  const handleLinks = (content: string) => {
    const slices = content.split(WEBSITE_REGEX)

    return slices.map((slice, index) => {
      if (WEBSITE_REGEX.test(slice)) {
        return (
          <TextComponent
            key={`link_${slice}_${index}`}
            title={slice}
            component="a"
            href={slice}
            target="_blank"
            rel="noopener noreferrer">
            {slice}
          </TextComponent>
        )
      }

      return slice
    })
  }

  const generateContent = useCallback(
    (content: string) => {
      const contentWithLinks = handleLinks(content)
      return handleMentions(contentWithLinks)
    },
    [handleMentions],
  )

  const TextContent = useCallback(
    (children: string) => (
      <TextComponent sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {generateContent(children)}
      </TextComponent>
    ),
    [generateContent],
  )

  const { handleToggleContent, isContentExpandVisible, isContentExpanded } =
    useContentExpanded({
      content: sanitizedContent,
      contentLimitChars: adjustedLimit,
    })

  const text = isContentExpandVisible
    ? `${sanitizedContent.substring(0, adjustedLimit)}...`
    : sanitizedContent

  return {
    isContentExpandVisible,
    isContentExpanded,
    content: TextContent(text),
    handleToggleContent,
  }
}
