import React from 'react'

import { StyledDialogComponent } from '@/presentation/dialogs/List/ListDialog.styles'
import { IListDialogProps } from '@/presentation/dialogs/List/ListDialog.types'
import { BoxComponent } from '@codebox-team/web/components'

export const ListDialog: React.FC<IListDialogProps> = ({
  header,
  list,
  isOpen,
  onClose,
}) => (
  <StyledDialogComponent
    open={isOpen}
    onClose={onClose}
    fullWidth={true}
    headerOnClose={onClose}
    headerTitle={header}
    fullScreen={false}
    testID="List dialog">
    <BoxComponent sx={{ overflowY: 'auto' }}>{list}</BoxComponent>
  </StyledDialogComponent>
)
