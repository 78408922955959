import { useEffect, useRef, useState } from 'react'

import { ITermsConditionsDialogProps } from '@/presentation/dialogs/TermsConditions/TermsConditionsDialog.types'
import { useToast } from '@/presentation/hooks/UseToast/UseToast'
import { useDispatch, useSelector } from '@codebox-team/redux'
import {
  envDecryptedCore,
  selectTermsConditionsDecision,
  selectTermsConditionsDecisionStatus,
  selectUserIsTermsConditionsConfirmed,
  termsConditionsDecisionRequest,
  termsConditionsDecisionResponse,
  useTermsConditions,
} from '@move-share-celebrate/core'

export const useTermsConditionsDialogRules = (
  props: ITermsConditionsDialogProps,
) => {
  const [isForcedDisabled, setIsForcedDisabled] = useState<boolean>(false)
  const isInitialized = useRef<boolean>(false)

  const dispatch = useDispatch()
  const { handleFetch, data, isWaiting } = useTermsConditions()
  const decisionStatus = useSelector(selectTermsConditionsDecisionStatus)
  const decision = useSelector(selectTermsConditionsDecision)

  const isTermsConditionsConfirmed = useSelector(
    selectUserIsTermsConditionsConfirmed,
  )

  const isSubmitting = decisionStatus?.type === 'waiting'
  const isSubmittingDecline = isSubmitting && !decision
  const isSubmittingAgreed = isSubmitting && decision

  const isSuccess = decisionStatus?.type === 'success'

  useToast({ type: 'error', actionStatus: decisionStatus })

  const handleDecisionTerms = (accepted: boolean) => {
    dispatch(
      termsConditionsDecisionRequest({
        request: {
          accepted,
          key: envDecryptedCore.TERMS_CONDITIONS_KEY,
        },
      }),
    )
  }

  useEffect(() => {
    if (isSuccess) {
      setIsForcedDisabled(true)

      dispatch(
        termsConditionsDecisionResponse({
          response: {
            status: {
              type: 'initial',
            },
          },
        }),
      )

      if (!decision) window.location.href = 'https://www.airliquide.com/'
    }
  }, [dispatch, decision, isSuccess, props])

  useEffect(() => {
    if (!isInitialized.current && !isTermsConditionsConfirmed) {
      isInitialized.current = true
      handleFetch({ key: envDecryptedCore.TERMS_CONDITIONS_KEY })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data,
    isWaiting,
    isForcedDisabled,
    isSubmitting,
    isSubmittingDecline,
    isSubmittingAgreed,
    handleDecisionTerms,
  }
}
