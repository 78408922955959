import { useCallback, useState } from 'react'

import {
  IHandleCommentSubmit,
  IHandleSubmitCommentUpdate,
  IHandleDeleteComment,
} from '@/application/contexts/Post/PostContext.types'
import { useHandlePost } from '@/presentation/hooks/UseHandlePost/UseHandlePost'
import { useQuery } from '@/presentation/hooks/UseQuery/UseQuery'
import { useRequestsHandleStatus } from '@/presentation/hooks/UseRequestsHandleStatus/UseRequestsHandleStatus'
import { useDispatch, useSelector } from '@codebox-team/redux'
import {
  IComment,
  commentCreateResponse,
  commentDeleteResponse,
  commentEditResponse,
  selectCommentCreateData,
  selectCommentCreateStatus,
  selectCommentDelete,
  selectCommentEdit,
  selectUserDetails,
  useComment,
  useEngagementContext,
  usePostDetails,
} from '@move-share-celebrate/core'

export const usePostProviderRules = () => {
  const [localComment, setLocalComment] = useState<IComment | null>(null)
  const [key, setKey] = useState<string>('')

  const dispatch = useDispatch()
  const query = useQuery()

  const selectedComment = query.get('selectedComment')

  const {
    localComments,
    hasMorePages,
    isWaitingComments,
    handleCancelUpdateComment,
    handleInitComments,
    handleInitUpdateComment,
    handleNextPageComment,
    ...commentHook
  } = useComment(dispatch)

  const { data, isWaiting, handleFetch, isFailure } = usePostDetails()
  const engagementHook = useEngagementContext()

  const createdCommentData = useSelector(selectCommentCreateData)
  const createCommentStatus = useSelector(selectCommentCreateStatus)
  const commentEdit = useSelector(selectCommentEdit)
  const commentDelete = useSelector(selectCommentDelete)

  const userDetails = useSelector(selectUserDetails)

  const handleCommentSubmit = ({ postKey, data }: IHandleCommentSubmit) =>
    commentHook.handleCreateComment({ postKey, userDetails, ...data })

  const handleSubmitCommentUpdate = ({
    postKey,
    comment,
    data,
  }: IHandleSubmitCommentUpdate) => {
    commentHook.handleUpdateComment({
      commentEdited: { ...comment, ...data },
      isUpdateLocal: false,
      postKey,
    })
    commentHook.handleResetLocalComment(comment.key)

    setLocalComment({ ...comment, ...data })
  }

  const handleDeleteComment = ({ postKey, comment }: IHandleDeleteComment) => {
    commentHook.handleDeleteComment({
      commentKeyToDelete: comment.key,
      isDeleteLocal: false,
      postKey,
    })
    setLocalComment(comment)
  }

  const handleInit = useCallback(
    (postKey: string) => {
      setKey(postKey)
      handleFetch({ key: postKey })
      handleInitComments(postKey, selectedComment)
    },
    [handleFetch, handleInitComments, selectedComment],
  )

  useHandlePost({
    status: createCommentStatus,
    onSuccess: () => {
      commentHook.handleSetCreatedComment(createdCommentData.key)
      dispatch(
        commentCreateResponse({
          response: { status: { type: 'initial' } },
        }),
      )
      engagementHook.handleIncrement({
        referenceKey: key,
        referenceType: 'post',
        type: 'comment',
      })
    },
    onFailure: commentHook.handleResetLocalCommentsOnError,
  })

  const { isWaiting: isDeletingComment } = useRequestsHandleStatus({
    statusToWatch: [commentDelete?.type],
    onComplete: () => {
      if (localComment) {
        commentHook.handleDeleteLocalComment(localComment.key)
        dispatch(
          commentDeleteResponse({
            response: { type: 'initial' },
          }),
        )
        engagementHook.handleDecrement({
          referenceKey: key,
          referenceType: 'post',
          type: 'comment',
        })
        setLocalComment(null)
      }
    },
    onFailure: () => commentHook.handleResetLocalCommentsOnError(),
  })

  useRequestsHandleStatus({
    statusToWatch: [commentEdit?.type],
    onComplete: () => {
      if (localComment) {
        commentHook.handleUpdateLocalComment(localComment)
        dispatch(
          commentEditResponse({
            response: { type: 'initial' },
          }),
        )
        setLocalComment(null)
      }
    },
    onFailure: () => commentHook.handleResetLocalCommentsOnError(),
  })

  useHandlePost({
    status: commentDelete,
    onSuccess: () => {
      dispatch(
        commentDeleteResponse({
          response: { type: 'initial' },
        }),
      )
    },
  })

  useHandlePost({
    status: commentEdit,
    onSuccess: () => {
      dispatch(
        commentEditResponse({
          response: { type: 'initial' },
        }),
      )
    },
  })

  return {
    data,
    isWaiting,
    handleFetch,
    isFailure,
    isDeletingComment,
    handleCommentSubmit,
    handleSubmitCommentUpdate,
    handleDeleteComment,
    handleInit,
    localComments,
    selectedComment,
    hasMorePages,
    isWaitingComments,
    handleCancelUpdateComment,
    handleInitComments,
    handleInitUpdateComment,
    handleNextPageComment,
  }
}
