import React from 'react'

import { ItemWrapper } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent.styles'
import { IIterativeItemComponentProps } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent.types'
import { BoxComponent } from '@codebox-team/web/components'
import {
  IconComponent,
  TextComponent,
  BadgeComponent,
} from '@codebox-team/web/components'

export const IterativeItemComponent: React.FC<IIterativeItemComponentProps> = ({
  text,
  svgIcon: SvgIcon,
  itemOrientation = 'column',
  textProps = {},
  testID,
  badgeContent,
  isSelected,
  onClick,
}) => {
  const Icon = (
    <BoxComponent
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={itemOrientation !== 'row' && '4px'}
      width="24px"
      height="24px">
      {typeof SvgIcon === 'string' ? (
        <IconComponent icon={SvgIcon as string} />
      ) : (
        <SvgIcon />
      )}
    </BoxComponent>
  )

  return (
    <ItemWrapper
      flexDirection={itemOrientation}
      fontSize="24px"
      selected={isSelected}
      onClick={onClick}
      testID={testID}>
      {!!badgeContent ? (
        <BadgeComponent badgeContent={badgeContent} color="error">
          {Icon}
        </BadgeComponent>
      ) : (
        Icon
      )}

      {!!text && (
        <TextComponent
          fontSize="12px"
          fontWeight={isSelected ? '500' : '400'}
          marginLeft={itemOrientation === 'row' && '8px'}
          {...textProps}>
          {text}
        </TextComponent>
      )}
    </ItemWrapper>
  )
}
