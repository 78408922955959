import { useEffect, useMemo, useRef } from 'react'

import { useFeedContext } from '@/application/contexts/Feed/FeedContext'
import { usePostContext } from '@/application/contexts/Post/PostContext'
import { useSelector } from '@codebox-team/redux'
import {
  selectUserDetails,
  FeedListNamespace,
  selectPostEditState,
  selectPostCreateState,
  selectPostDeleteState,
} from '@move-share-celebrate/core'

const defaultFeedParams: Pick<FeedListNamespace.IRequest, 'page' | 'pageSize'> =
  {
    page: 1,
    pageSize: 5,
  }

export const useLatestPostsComponentRules = () => {
  const isInitialized = useRef<boolean>(false)
  const userDetails = useSelector(selectUserDetails)

  const { isFailure } = usePostContext()
  const {
    latestHook: { posts, isWaitingFeed: isWaiting, handleInitFeed },
  } = useFeedContext()

  const { submit: updatePostSubmit } = useSelector(selectPostEditState)
  const { submit: createPostSubmit } = useSelector(selectPostCreateState)
  const { submit: deletePostSubmit } = useSelector(selectPostDeleteState)

  const isRefetchEnabled = useMemo(
    () =>
      updatePostSubmit?.type === 'success' ||
      createPostSubmit?.status.type === 'success' ||
      deletePostSubmit?.type === 'success' ||
      isFailure,
    [
      createPostSubmit?.status.type,
      deletePostSubmit?.type,
      isFailure,
      updatePostSubmit?.type,
    ],
  )

  useEffect(() => {
    if (isRefetchEnabled) {
      handleInitFeed({ ...defaultFeedParams, authorKey: userDetails?.key })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchEnabled, userDetails?.key])

  useEffect(() => {
    if (!isInitialized.current && userDetails?.key) {
      isInitialized.current = true
      handleInitFeed({
        ...defaultFeedParams,
        authorKey: userDetails?.key,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.key])

  return { posts, isWaiting }
}
