import { useCallback, useEffect, useState } from 'react'

import {
  IStatusArray,
  IUseRequestsHandleStatusHookProps,
} from '@/presentation/hooks/UseRequestsHandleStatus/UseRequestsHandleStatus.types'

export const useRequestsHandleStatus = ({
  statusToWatch,
  onComplete,
  onFailure,
}: IUseRequestsHandleStatusHookProps) => {
  const [waitingArray, setWaitingArray] = useState<IStatusArray[] | undefined>(
    undefined,
  )

  const statusArray = statusToWatch.map((status, index) => ({ status, index }))

  const handleStatus = useCallback(() => {
    const isComplete = waitingArray?.every(
      ({ index }) => statusArray[index]?.status === 'success',
    )

    const isFailure = waitingArray?.some(
      ({ index }) => statusArray[index]?.status === 'failure',
    )

    if (isFailure) onFailure?.()

    if (isComplete) onComplete?.()

    const newWaitingArray = statusArray.filter(
      ({ status }) => status === 'waiting',
    )

    setWaitingArray(!!newWaitingArray.length ? newWaitingArray : undefined)
  }, [onComplete, onFailure, statusArray, waitingArray])

  useEffect(() => {
    handleStatus()
  }, [handleStatus])

  return { isWaiting: !!waitingArray }
}
