import { PropsWithChildren, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from '@codebox-team/redux'
import {
  notificationsUnreadRequest,
  selectUserIsAuthenticated,
} from '@move-share-celebrate/core'

export const NotificationsUnreadProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isInitialized = useRef<boolean>(false)
  const dispatch = useDispatch()
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  useEffect(() => {
    if (isUserAuthenticated && !isInitialized.current) {
      isInitialized.current = true
      dispatch(notificationsUnreadRequest())
    }
  }, [dispatch, isUserAuthenticated])

  return children
}
