import { BoxComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const ItemWrapper = StyleSheet.create(BoxComponent)<{
  selected: boolean
}>(({ theme, selected, onClick }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: onClick && 'pointer',
  color: selected ? theme.palette.secondary.main : theme.palette.text.secondary,

  path: {
    fill: selected && theme.palette.secondary.main,
  },

  ['&:hover']: {
    color: onClick && theme.palette.secondary.main,

    path: {
      fill: onClick && theme.palette.secondary.main,
    },
  },

  button: {
    padding: 0,
  },

  ['& .MuiBadge-badge']: {
    minWidth: '15px',
    padding: '0 3px',
    height: 'fit-content',
    width: 'fit-content',

    aspectRatio: '1 / 1',
    borderRadius: '50%',

    top: '15%',
    right: '15%',
  },
}))
