import { firebaseConfig } from '@move-share-celebrate/core'

import {
  initializeAnalytics,
  setUserProperties,
  Analytics,
} from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'

export class FirebaseConfig {
  public static app: FirebaseApp
  public static analytics: Analytics

  static initializeApp = () => {
    if (!FirebaseConfig.app) {
      FirebaseConfig.app = initializeApp(firebaseConfig.firebaseConfig)
      FirebaseConfig.analytics = initializeAnalytics(FirebaseConfig.app)
    }

    return FirebaseConfig.app
  }

  static setUserProperties = (properties: Record<string, string>) => {
    setUserProperties(FirebaseConfig.analytics, properties, { global: true })
  }
}
