import React from 'react'

import { InfiniteScrollWrapper } from '@/presentation/components/Surfaces/InfiniteScrollWrapper/InfiniteScrollWrapperComponent.styles'
import { IInfiniteScrollWrapperComponentProps } from '@/presentation/components/Surfaces/InfiniteScrollWrapper/InfiniteScrollWrapperComponent.types'

export const InfiniteScrollWrapperComponent: React.FC<
  IInfiniteScrollWrapperComponentProps
> = ({ children, testID }) => (
  <InfiniteScrollWrapper testID={testID}>{children}</InfiniteScrollWrapper>
)
