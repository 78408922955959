import React from 'react'

import { BoxComponent, SkeletonComponent } from '@codebox-team/web/components'

export const AccountsListComponentSkeleton: React.FC = () =>
  [...new Array(4).keys()].map(index => (
    <BoxComponent
      display="flex"
      gap="10px"
      marginTop="8px"
      key={index}
      testID="Accounts list skeleton">
      <SkeletonComponent width={40} height={40} variant="circular" />
      <BoxComponent display="flex" flexDirection="column" gap="10px">
        <SkeletonComponent
          width={232}
          height={17}
          variant="rectangular"
          sx={{ borderRadius: '4px' }}
        />

        <SkeletonComponent
          width={172}
          height={17}
          variant="rectangular"
          sx={{ borderRadius: '4px' }}
        />
      </BoxComponent>
    </BoxComponent>
  ))
