import React from 'react'

import { GlobalStyles } from '@codebox-team/web/components'

export const ThemeGlobalStyles: React.FC = () => (
  <GlobalStyles
    styles={theme => ({
      a: {
        color: theme.palette.secondary.main,
        fontWeight: theme.tokens.FontWeight[700],
      },
      body: {
        backgroundColor: theme.palette.grey[100],
      },
      html: {
        fontSize: theme.tokens.FontSize[14],
      },
    })}
  />
)
