import React, { useEffect } from 'react'

import { LatestPostsComponent } from '@/presentation/components/Data/LatestPosts/LatestPostsComponent'
import { StyledComponentsWrapper } from '@/presentation/components/Layout/Application/ApplicationLayoutComponent.styles'
import { FooterNavigationComponent } from '@/presentation/components/Navigation/Footer/FooterNavigationComponent'
import { HeaderComponent } from '@/presentation/components/Navigation/Header/HeaderComponent'
import { UserProfileCardComponent } from '@/presentation/components/Surfaces/UserProfileCard/UserProfileCardComponent'
import { TermsConditionsDialog } from '@/presentation/dialogs/TermsConditions/TermsConditionsDialog'
import { useIsDownBreakpoint } from '@/presentation/hooks/UseIsDownBreakpoint/UseIsDownBreakpoint'
import { useIsPathMatched } from '@/presentation/hooks/UseIsPathMatched/UseIsPathMatched'
import { useDispatch, useSelector } from '@codebox-team/redux'
import { BoxComponent } from '@codebox-team/web/components'
import {
  selectUserDetails,
  selectUserIsAuthenticated,
  selectUserIsTermsConditionsConfirmed,
  userDetailsPhotoRequest,
} from '@move-share-celebrate/core'

export const ApplicationLayoutComponent: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const isTermsConditionsConfirmed = useSelector(
    selectUserIsTermsConditionsConfirmed,
  )

  const { isLgDown } = useIsDownBreakpoint()
  const { isPathMatched } = useIsPathMatched()
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)
  const userDetails = useSelector(selectUserDetails)

  const isUserProfile = isPathMatched(`/profile/${userDetails?.key}`)

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(userDetailsPhotoRequest())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated])

  return (
    <React.Fragment>
      {!!isUserAuthenticated && <HeaderComponent />}
      {!isUserAuthenticated ? (
        children
      ) : (
        <StyledComponentsWrapper
          container="true"
          maxWidth="lg"
          width="100%"
          $isLgDown={isLgDown}>
          <BoxComponent display="flex" gap="32px" width="100%">
            {!isLgDown && !isUserProfile && (
              <UserProfileCardComponent
                photoUri={userDetails?.photoUrl}
                jobTitle={userDetails?.jobTitle}
                name={userDetails?.name}
              />
            )}

            <BoxComponent
              width={!isLgDown ? '636px' : '100%'}
              marginLeft={!isLgDown && isUserProfile && '232px'}>
              {children}
            </BoxComponent>

            {!isLgDown && !isUserProfile && <LatestPostsComponent />}
          </BoxComponent>

          <TermsConditionsDialog
            isOpen={isUserAuthenticated && !isTermsConditionsConfirmed}
          />
        </StyledComponentsWrapper>
      )}
      {!!isUserAuthenticated && isLgDown && <FooterNavigationComponent />}
    </React.Fragment>
  )
}
