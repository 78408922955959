import { createContext, useContext } from 'react'

import {
  IFeedContext,
  TFeedReturnType,
} from '@/application/contexts/Feed/FeedContext.types'

const feedHookInitialValue: TFeedReturnType = {
  posts: undefined,
  keywords: undefined,
  rawKeywords: undefined,
  selectedRawKeyword: undefined,
  totalPages: 0,
  page: 0,
  pageSize: 0,
  isLatestPostsWaiting: false,
  isPinnedFailure: false,
  isWaitingFeed: false,
  isWaitingKeywords: false,
  isLatestFailure: false,
  handleFetchKeywords: async () => ({} as any),
  handleInitFeed: async () => ({} as any),
  handleNextPageFeed: async () => ({} as any),
  handleSetKeywords: async () => ({} as any),
}

export const initialFeedContextValue: IFeedContext = {
  feedHook: feedHookInitialValue,
  latestHook: feedHookInitialValue,
  profileHook: feedHookInitialValue,
  isDetails: false,
}

export const FeedContext = createContext<IFeedContext>(initialFeedContextValue)

export const useFeedContext = () => useContext<IFeedContext>(FeedContext)
