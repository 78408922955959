import React from 'react'

import { ProfileComponent } from '@/presentation/components/Data/Profile/ProfileComponent'
import { StyledCardComponent } from '@/presentation/components/Surfaces/UserProfileCard/UserProfileCardComponent.styles'
import { IUserProfileCardComponentProps } from '@/presentation/components/Surfaces/UserProfileCard/UserProfileCardComponent.types'

export const UserProfileCardComponent: React.FC<
  IUserProfileCardComponentProps
> = ({ jobTitle, name, photoUri }) => (
  <StyledCardComponent data-testid="User profile card">
    <ProfileComponent
      jobTitle={jobTitle}
      name={name}
      photoUri={photoUri}
      avatarSize={{ width: '72px', height: '72px' }}
    />
  </StyledCardComponent>
)
