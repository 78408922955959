import React, { Suspense, useMemo } from 'react'

import { useRoutesConfig } from '@/application/routes/RoutesConfig.rules'
import { ApplicationLayoutComponent } from '@/presentation/components/Layout/Application/ApplicationLayoutComponent'
import { useSelector } from '@codebox-team/redux'
import { RouteComponent } from '@codebox-team/web/components'
import { selectUserIsAuthenticated } from '@move-share-celebrate/core'

export const RoutesConfig: React.FC = () => {
  const { appPrivateRoutes, appPublicRoutes } = useRoutesConfig()

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  const routes = useMemo(
    () =>
      [...appPrivateRoutes, ...appPublicRoutes].filter(
        route => !route?.isHidden,
      ),
    [appPrivateRoutes, appPublicRoutes],
  )

  return (
    <Suspense fallback={false}>
      <ApplicationLayoutComponent>
        {routes.map((routeProps, routeIndexProps) => (
          <RouteComponent
            {...routeProps}
            key={routeIndexProps}
            isUserAuthenticated={isUserAuthenticated}
          />
        ))}
      </ApplicationLayoutComponent>
    </Suspense>
  )
}
