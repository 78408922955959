import { BoxComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledComponentsWrapper = StyleSheet.create(BoxComponent)<{
  $isLgDown?: boolean
}>(({ $isLgDown, theme }) => ({
  margin: 'calc(64px + 24px) auto auto',
  marginBottom: $isLgDown ? theme.tokens.SpacingValues[160] : 'auto',
  padding: $isLgDown ? '0 8px' : '0',
}))
