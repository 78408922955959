import { useTheme, useMediaQuery } from '@codebox-team/web/hooks'

export const useIsDownBreakpoint = () => {
  const theme = useTheme()

  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return {
    isLgDown,
    isMdDown,
    isSmDown,
  }
}
