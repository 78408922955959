import React from 'react'
import { useHistory } from 'react-router'

import { ProfileComponent } from '@/presentation/components/Data/Profile/ProfileComponent'
import { AccountsListComponentSkeleton } from '@/presentation/components/Surfaces/AccountsList/AccountsListComponent.skeleton'
import { IAccountsListComponentProps } from '@/presentation/components/Surfaces/AccountsList/AccountsListComponent.types'
import { InfiniteScrollWrapperComponent } from '@/presentation/components/Surfaces/InfiniteScrollWrapper/InfiniteScrollWrapperComponent'
import {
  BoxComponent,
  InfiniteScrollComponent,
} from '@codebox-team/web/components'

export const AccountsListComponent: React.FC<IAccountsListComponentProps> = ({
  accounts,
  page,
  totalPages,
  isWaiting,
  onClose,
  onLoadMore,
}) => {
  const history = useHistory()

  if (isWaiting && accounts.length <= 0) {
    return <AccountsListComponentSkeleton />
  }

  return (
    <InfiniteScrollWrapperComponent testID="Accounts list component">
      <InfiniteScrollComponent
        items={accounts}
        page={page}
        isBusy={isWaiting}
        totalPages={totalPages}
        renderItem={(account, index) => (
          <BoxComponent
            paddingY="8px"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onClose?.()
              history.push(`/profile/${account.key}`)
            }}
            testID={`Accounts entry ${index}`}>
            <ProfileComponent
              jobTitle={account?.jobTitle}
              name={account?.name}
              photoUri={account?.photoUrl}
              profileOrientation="row"
              avatarSize={{ width: '40px', height: '40px' }}
            />
          </BoxComponent>
        )}
        handleLoadMore={onLoadMore}
      />
    </InfiniteScrollWrapperComponent>
  )
}
