import { FirebaseConfig } from '@/infra/Firebase/FirebaseConfig'
import { firebaseConfig } from '@move-share-celebrate/core'

import {
  Unsubscribe,
  getToken,
  onMessage,
  getMessaging,
} from 'firebase/messaging'

export class PushNotificationConfig {
  public static unsubscribe: Unsubscribe

  handle = async (): Promise<string | null> => {
    try {
      if (!('Notification' in window)) {
        return null
      }

      const permission = await Promise.race([
        Notification.requestPermission(),
        new Promise<NotificationPermission>((resolve, _reject) => {
          setTimeout(() => resolve('denied'), 5000)
        }),
      ])

      if (permission === 'denied') {
        return null
      }

      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
      )

      const app = FirebaseConfig.initializeApp()

      const messaging = getMessaging(app)

      const token = await getToken(messaging, {
        serviceWorkerRegistration,
        vapidKey: firebaseConfig.messageConfig.vapidKey,
      })

      PushNotificationConfig.unsubscribe = onMessage(
        messaging,
        async payload => {
          const { notification, data } = payload

          const registrations = await navigator.serviceWorker.getRegistrations()

          if (registrations) {
            const serviceWorker = registrations[0]

            serviceWorker.showNotification(notification.title, {
              body: notification.body,
              icon: '/notification.png',
              data: data,
            })
          }
        },
      )

      return token
    } catch {
      return null
    }
  }
}
