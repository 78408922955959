import React, { useEffect } from 'react'

import { RoutesConfig } from '@/application/routes/RoutesConfig'
import { FirebaseConfig } from '@/infra/Firebase/FirebaseConfig'
import { PlatformConfig } from '@/infra/Platform/PlatformConfig'
import { PushNotificationConfig } from '@/infra/PushNotification/PushNotificationConfig'
import { ApplicationProvider } from '@/presentation/components/Providers/Application/ApplicationProvider'
import { WebMonitor } from '@codebox-team/web-monitor'
import {
  HttpClientMonitorDecorator,
  SignInFacadeDecorator,
  FirebaseConfig as FirebaseCoreConfig,
} from '@move-share-celebrate/core'

if (!__DEV__) {
  WebMonitor.init({
    dsn: 'https://3ac18aa74aa94417b83ea1c3a9a26a73@o4505242138574848.ingest.sentry.io/4506145376043008',
    tracesSampleRate: 1.0,
  })
  HttpClientMonitorDecorator.addMonitor(WebMonitor)
}

FirebaseConfig.initializeApp()
FirebaseCoreConfig.addSetUserProperties(FirebaseConfig.setUserProperties)

const notifications = new PushNotificationConfig()
const platform = new PlatformConfig(notifications)

SignInFacadeDecorator.addPlatformCredentials({
  getCredentials: () => platform.handle(),
})

export const App = () => {
  useEffect(() => {
    return () => {
      PushNotificationConfig.unsubscribe?.()
    }
  }, [])

  return (
    <ApplicationProvider>
      <RoutesConfig />
    </ApplicationProvider>
  )
}
