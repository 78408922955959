import { HeaderComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledHeaderComponent = StyleSheet.create(HeaderComponent)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: '0px 0px 12px 12px',

    boxShadow:
      '0px -3px 3px -2px #00000033, 0px -3px 4px 0px #00000024, 0px -1px 8px 0px #0000001f',

    [`${theme.breakpoints.down('lg')}`]: {
      padding: '0 8px',
    },
  }),
)
