import { getSemanticVersion } from '@/application/utils/PackageVersion/PackageVersionUtil'
import { PushNotificationConfig } from '@/infra/PushNotification/PushNotificationConfig'
import { TPlatform } from '@codebox-team/core'

export class PlatformConfig {
  constructor(private readonly notifications: PushNotificationConfig) {}

  handle = async () => {
    const token = await this.notifications.handle()

    return {
      token,
      platform: 'WEB' as TPlatform,
      version: getSemanticVersion(),
    }
  }
}
