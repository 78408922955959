import { CardComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledCardComponent = StyleSheet.create(CardComponent)({
  position: 'sticky',
  top: 'calc(64px + 24px)',
  width: '200px',
  height: 'fit-content',
  wordBreak: 'break-all',
})
