import TagIconSvg from '@/presentation/assets/img/icon/tag-icon.svg'
import {
  IconButtonComponent,
  ButtonComponent,
} from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledIconButtonComponent = StyleSheet.create(IconButtonComponent)(
  ({ theme, onClick }) => ({
    position: 'fixed',
    top: theme.tokens.SpacingValues[10],
    left: '310px',
    borderRadius: '50% !important',
    padding: theme.tokens.Padding[8],
    backgroundColor: theme.palette.common.white,

    ['&:hover']: {
      color: onClick && theme.palette.error.main,

      backgroundColor: theme.palette.common.white,
    },
  }),
)

export const StyledButtonComponent = StyleSheet.create(ButtonComponent)(
  ({ theme, onClick }) => ({
    padding: theme.tokens.Padding[8],
    borderColor: theme.palette.text.disabled,

    ['&:hover']: {
      color: onClick && theme.palette.error.main,
      borderColor: theme.palette.text.disabled,
    },
  }),
)

export const StyledTagIconSvg = StyleSheet.create(TagIconSvg)(({ theme }) => ({
  fill: theme.palette.text.secondary,
}))
