import { useHistory } from 'react-router'

import { useHandlePost } from '@/presentation/hooks/UseHandlePost/UseHandlePost'
import { useDispatch, useSelector } from '@codebox-team/redux'
import {
  postCreateResponse,
  postDeleteResponse,
  postEditResponse,
  postPinResponse,
  postUnpinResponse,
  selectPostCreateState,
  selectPostDeleteState,
  selectPostEditState,
  selectPostPinState,
  selectPostUnpinState,
  useFeed,
} from '@move-share-celebrate/core'

export const useFeedProviderRules = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const feedHook = useFeed()
  const latestHook = useFeed()
  const profileHook = useFeed()

  const { submit: deletePostSubmit } = useSelector(selectPostDeleteState)
  const { submit: pinPostSubmit } = useSelector(selectPostPinState)
  const { submit: unpinPostSubmit } = useSelector(selectPostUnpinState)
  const { submit: updatePostSubmit } = useSelector(selectPostEditState)
  const { submit: createPostSubmit } = useSelector(selectPostCreateState)

  const isDetails = history.location.pathname.includes('details')
  const isRedirectOnDeleteSuccess =
    isDetails || history.location.pathname.includes('edit')

  useHandlePost({
    status: updatePostSubmit,
    isRedirectOnSuccess: true,
    onSuccess: () => {
      dispatch(postEditResponse({ response: { type: 'initial' } }))
    },
  })

  useHandlePost({
    status: deletePostSubmit,
    isRedirectOnSuccess: isRedirectOnDeleteSuccess,
    onSuccess: () => {
      dispatch(postDeleteResponse({ response: { type: 'initial' } }))
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  })

  useHandlePost({
    status: pinPostSubmit,
    onSuccess: () => {
      dispatch(postPinResponse({ response: { type: 'initial' } }))
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  })

  useHandlePost({
    status: unpinPostSubmit,
    onSuccess: () => {
      dispatch(postUnpinResponse({ response: { type: 'initial' } }))
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  })

  useHandlePost({
    status: createPostSubmit?.status,
    isRedirectOnSuccess: true,
    onSuccess: () => {
      dispatch(
        postCreateResponse({ response: { status: { type: 'initial' } } }),
      )
    },
  })

  return {
    feedHook,
    latestHook,
    profileHook,
    isDetails,
  }
}
