import React from 'react'

import { getCurrentLocale } from '@/application/utils/Translate/TranslateUtil'
import mscFrSmallLogo from '@/presentation/assets/img/logo/msc-small-logo-fr.png'
import mscSmallLogo from '@/presentation/assets/img/logo/msc-small-logo.png'

export const LogoComponent: React.FC = () => {
  const locale = getCurrentLocale()
  const logoSrc = locale.startsWith('fr-CA') ? mscFrSmallLogo : mscSmallLogo

  return <img src={logoSrc} height="37px" alt="logo" />
}
