import React from 'react'

import {
  FeedContext,
  initialFeedContextValue,
} from '@/application/contexts/Feed/FeedContext'
import { useFeedProviderRules } from '@/presentation/components/Providers/Feed/FeedProvider.rules'
import { useSelector } from '@codebox-team/redux'
import { selectUserIsAuthenticated } from '@move-share-celebrate/core'

export const FeedProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const contextValue = useFeedProviderRules()

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  return (
    <FeedContext.Provider
      value={isUserAuthenticated ? contextValue : initialFeedContextValue}>
      {children}
    </FeedContext.Provider>
  )
}
