import { BoxComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const InfiniteScrollWrapper = StyleSheet.create(BoxComponent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.tokens.Margin[0],

    '.MuiGrid-item': {
      padding: `${theme.tokens.Padding[0]} !important`,
    },

    '.MuiGrid-container': {
      width: '100%',
      margin: theme.tokens.Margin[0],
    },

    '& > *:first-of-type': {
      width: '100%',
      padding: theme.tokens.Padding[0],
      overflow: 'hidden',
    },
  }),
)
