import { useEffect, useRef, useState } from 'react'

export const useFileThumbnailComponentRules = () => {
  const ref = useRef<any | null>(null)
  const [isIntersecting, setIntersecting] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const isLazyLoad = !isIntersecting

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    )

    if (ref.current) observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref])

  const thumbnailStyle = {
    visibility: isLoaded ? 'visible' : 'hidden',
  } as React.CSSProperties

  return { thumbnailStyle, ref, isLoaded, isLazyLoad, setIsLoaded }
}
