import { useHistory } from 'react-router'

import { IUseHandlePostHookProps } from '@/presentation/hooks/UseHandlePost/UseHandlePost.types'
import { useToast } from '@/presentation/hooks/UseToast/UseToast'

export const useHandlePost = ({
  status,
  isRedirectOnSuccess,
  onSuccess,
  onFailure,
}: IUseHandlePostHookProps) => {
  const history = useHistory()

  const isWaiting = status?.type === 'waiting'
  const isSuccess = status?.type === 'success'
  const isFailure = status?.type === 'failure'

  useToast({
    type: 'success',
    actionStatus: {
      type: status?.type,
      message: status?.message,
    },
    onDispatchActionStatusCleaner: () => {
      if (isSuccess && onSuccess) onSuccess()
      if (isSuccess && isRedirectOnSuccess) history.push('/')
    },
  })

  useToast({
    type: 'error',
    actionStatus: {
      type: status?.type,
      message: status?.message,
    },
    onDispatchActionStatusCleaner: () => {
      if (isFailure) onFailure?.()
    },
  })

  return { isWaiting, isFailure, isSuccess }
}
