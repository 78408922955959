import React from 'react'
import { useHistory } from 'react-router'

import AirLiquideLogo from '@/presentation/assets/img/logo/airliquide-logo.svg'
import { LogoComponent } from '@/presentation/components/Data/Logo/LogoComponent'
import { DrawerNavigationComponent } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent'
import { useHeaderComponentRules } from '@/presentation/components/Navigation/Header/HeaderComponent.rules'
import { StyledHeaderComponent } from '@/presentation/components/Navigation/Header/HeaderComponent.styles'
import { IterativeItemComponent } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent'
import { useSelector } from '@codebox-team/redux'
import { BoxComponent } from '@codebox-team/web/components'
import { selectUserIsAuthenticated } from '@move-share-celebrate/core'

export const HeaderComponent: React.FC = () => {
  const { headerItems, notificationsUnread, isDrawerOpen, setIsDrawerOpen } =
    useHeaderComponentRules()
  const history = useHistory()

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  return (
    <StyledHeaderComponent data-testid="Header Component">
      {isUserAuthenticated && (
        <DrawerNavigationComponent
          notificationsUnread={notificationsUnread}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
        />
      )}

      <BoxComponent
        display="flex"
        alignItems="center"
        gap="32px"
        maxWidth="lg"
        width="100%">
        <BoxComponent display="flex" width="200px" gap="24px">
          {headerItems.left
            .filter(({ isHidden }) => !isHidden)
            .map(item => (
              <IterativeItemComponent
                key={`left_header_item_${item.text}`}
                {...item}
              />
            ))}
          <BoxComponent
            sx={{ cursor: 'pointer' }}
            onClick={() => history.push('/')}>
            <AirLiquideLogo />
          </BoxComponent>
        </BoxComponent>

        <BoxComponent display="flex" width="636px" justifyContent="center">
          {headerItems.right
            .filter(({ isHidden }) => !isHidden)
            .map(item => (
              <BoxComponent flex="1" key={`right_header_item_${item.text}`}>
                <IterativeItemComponent {...item} />
              </BoxComponent>
            ))}
        </BoxComponent>

        <BoxComponent display="flex" justifyContent="flex-end" width="300px">
          <LogoComponent />
        </BoxComponent>
      </BoxComponent>
    </StyledHeaderComponent>
  )
}
