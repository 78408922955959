import React from 'react'
import { useHistory } from 'react-router'

import EditIcon from '@/presentation/assets/img/icon/edit-icon.svg'
import { FileThumbnailComponent } from '@/presentation/components/Data/FileViewer/FileThumbnail/FileThumbnailComponent'
import { ThumbnailWrapper } from '@/presentation/components/Surfaces/LatestPosts/Item/LatestPostItemComponent.styles'
import { ILatestPostItemComponentProps } from '@/presentation/components/Surfaces/LatestPosts/Item/LatestPostItemComponent.types'
import { PostInfoBarComponent } from '@/presentation/components/Surfaces/PostInfoBar/PostInfoBarComponent'
import { useContent } from '@/presentation/hooks/UseContent/UseContent'
import { BoxComponent } from '@codebox-team/web/components'
import { useEngagementContext } from '@move-share-celebrate/core'

const MAX_CHARS_IN_CONTENT = 56
const LINE_REGEX = /\r\n|\r|\n/
const MAX_LINES = 2

export const LatestPostItemComponent: React.FC<
  ILatestPostItemComponentProps
> = ({ post }) => {
  const { engagements } = useEngagementContext()
  const history = useHistory()
  const lineFixedContent = post.content
    .split(LINE_REGEX)
    .slice(0, MAX_LINES)
    .join('\n')

  const { content } = useContent({
    content: lineFixedContent,
    contentLimitChars: MAX_CHARS_IN_CONTENT,
  })

  const likes = engagements[post.key]?.like?.total || 0
  const comments = engagements[post.key]?.comment?.total || 0

  return (
    <BoxComponent
      data-testid="Last post item component"
      display="flex"
      gap="8px"
      sx={{ cursor: 'pointer' }}
      onClick={() => history.push(`/post/${post.key}/details`)}>
      <ThumbnailWrapper>
        {!!post.medias.length ? (
          <FileThumbnailComponent media={post.medias[0]} />
        ) : (
          <EditIcon />
        )}
      </ThumbnailWrapper>

      <BoxComponent display="flex" flex="1" flexDirection="column" gap="6px">
        {content}

        <PostInfoBarComponent likes={likes} comments={comments} />
      </BoxComponent>
    </BoxComponent>
  )
}
