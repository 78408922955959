import React from 'react'

import { IProfileComponentProps } from '@/presentation/components/Data/Profile/ProfileComponent.types'
import {
  BoxComponent,
  AvatarComponent,
  TextComponent,
} from '@codebox-team/web/components'

export const ProfileComponent: React.FC<IProfileComponentProps> = ({
  name,
  jobTitle,
  photoUri,
  jobTitlePrefix,
  profileOrientation = 'column',
  avatarSize = { width: '40px', height: '40px' },
}) => (
  <BoxComponent
    display="flex"
    gap="12px"
    alignItems={profileOrientation === 'row' ? 'flex-start' : 'center'}
    flexDirection={profileOrientation}>
    <AvatarComponent sx={avatarSize} src={photoUri} alt={name} />

    <BoxComponent
      display="flex"
      flexDirection="column"
      gap={profileOrientation === 'column' && '8px'}
      textAlign={profileOrientation === 'column' ? 'center' : 'left'}>
      <TextComponent fontWeight="bold" fontSize="16px">
        {name}
      </TextComponent>
      <TextComponent lineHeight="16px" whiteSpace="pre-wrap">
        {!!jobTitlePrefix && (
          <TextComponent
            component="span"
            fontSize="12px"
            lineHeight="16px"
            color="text.secondary">
            {jobTitlePrefix}
          </TextComponent>
        )}

        {!!jobTitle && (
          <TextComponent
            component="span"
            fontSize="12px"
            lineHeight="16px"
            color="text.disabled"
            sx={{ wordBreak: 'break-word' }}>
            {!!jobTitlePrefix && ' • '}
            {jobTitle}
          </TextComponent>
        )}
      </TextComponent>
    </BoxComponent>
  </BoxComponent>
)
