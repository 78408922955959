import { useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FeedSvg from '@/presentation/assets/img/icon/feed-icon.svg'
import NotificationSvg from '@/presentation/assets/img/icon/notifications-icon.svg'
import { IHeaderNavigationItems } from '@/presentation/components/Navigation/Header/HeaderComponent.types'
import { getFixedNotificationsUnread } from '@/presentation/components/Utils/GetFixedNotificationsUnread/GetFixedNotificationsUnreadUtils'
import { useIsDownBreakpoint } from '@/presentation/hooks/UseIsDownBreakpoint/UseIsDownBreakpoint'
import { useIsPathMatched } from '@/presentation/hooks/UseIsPathMatched/UseIsPathMatched'
import { useSelector } from '@codebox-team/redux'
import {
  selectNotificationsUnread,
  selectUserIsAuthenticated,
} from '@move-share-celebrate/core'

export const useHeaderComponentRules = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const { isLgDown } = useIsDownBreakpoint()
  const history = useHistory()

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)
  const notificationsUnread = useSelector(selectNotificationsUnread)

  const { isPathMatched } = useIsPathMatched()

  const showLeftItems = isUserAuthenticated && !isLgDown

  const headerItems: IHeaderNavigationItems = useMemo(() => {
    return {
      left: [
        {
          svgIcon: 'menu',
          onClick: () => setIsDrawerOpen(!isDrawerOpen),
          isHidden: !isUserAuthenticated,
        },
      ],
      right: [
        {
          svgIcon: FeedSvg,
          onClick: () => history.push('/'),
          text: translate.t('menu.items.feed'),
          isSelected: isPathMatched('/'),
          isHidden: !showLeftItems,
        },
        {
          svgIcon: 'add',
          text: translate.t('menu.items.new_post'),
          onClick: () => history.push('/new-post'),
          isSelected: isPathMatched('/new-post'),
          isHidden: !showLeftItems,
        },
        {
          svgIcon: NotificationSvg,
          text: translate.t('menu.items.notifications'),
          badgeContent: getFixedNotificationsUnread({ notificationsUnread }),
          onClick: () => history.push('/notifications'),
          isSelected: isPathMatched('/notifications'),
          isHidden: !showLeftItems,
        },
      ],
    }
  }, [
    history,
    isDrawerOpen,
    isPathMatched,
    isUserAuthenticated,
    notificationsUnread,
    showLeftItems,
  ])

  return { headerItems, notificationsUnread, isDrawerOpen, setIsDrawerOpen }
}
