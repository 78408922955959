import React from 'react'

import { BoxComponent, SkeletonComponent } from '@codebox-team/web/components'

export const LatestPostItemSkeletonComponent: React.FC = () => (
  <BoxComponent display="flex" gap="16px">
    <SkeletonComponent
      variant="rectangular"
      width={44}
      height={44}
      sx={{ borderRadius: '4px' }}
    />

    <BoxComponent display="flex" flexDirection="column" gap="10px">
      <SkeletonComponent
        variant="rectangular"
        width="100%"
        height={20}
        sx={{ borderRadius: '4px' }}
      />
      <SkeletonComponent
        variant="rectangular"
        width="100%"
        height={20}
        sx={{ borderRadius: '4px' }}
      />
      <BoxComponent display="flex" gap="10px">
        <SkeletonComponent
          variant="rectangular"
          width={16}
          height={16}
          sx={{ borderRadius: '50%' }}
        />
        <SkeletonComponent
          variant="rectangular"
          width={188}
          height={16}
          sx={{ borderRadius: '4px' }}
        />
      </BoxComponent>
    </BoxComponent>
  </BoxComponent>
)
