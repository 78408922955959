import { DialogComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledDialogComponent = StyleSheet.create(DialogComponent)(
  ({ theme }) => ({
    ['> div[role="presentation"]']: {
      ['> div[role="dialog"]']: {
        width: '552px',
        height: '570px',

        ['> h2']: {
          padding: '0 !important',
        },

        ['> div']: {
          paddingLeft: `${theme.tokens.Padding[16]}px !important`,
          paddingRight: `${theme.tokens.Padding[16]}px !important`,
          paddingBottom: `${theme.tokens.Padding[32]}px !important`,
        },
      },
    },
  }),
)
