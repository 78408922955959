import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { LatestPostItemSkeletonComponent } from '@/presentation/components/Surfaces/LatestPosts/Item/LatestPostItemComponent.skeleton'
import {
  BoxComponent,
  CardComponent,
  DividerComponent,
  TextComponent,
} from '@codebox-team/web/components'

export const LatestPostsCardSkeletonComponent: React.FC = () => (
  <CardComponent
    testID="Last posts card"
    withoutContentWrapper
    sx={{ height: 'fit-content' }}>
    <BoxComponent
      display="flex"
      flexDirection="column"
      padding="16px 12px"
      width="300px">
      <TextComponent
        fontSize="16px"
        color="text.secondary"
        fontWeight="500"
        marginBottom="16px">
        {translate.t('post.latest.title')}
      </TextComponent>

      <LatestPostItemSkeletonComponent />

      <DividerComponent sx={{ marginY: '16px' }} />

      <LatestPostItemSkeletonComponent />
    </BoxComponent>
  </CardComponent>
)
