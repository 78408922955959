import React from 'react'

import { BoxComponent, SkeletonComponent } from '@codebox-team/web/components'

export const TermsConditionsDialogTitleSkeleton: React.FC = () => (
  <SkeletonComponent
    variant="rectangular"
    width={80}
    height={16}
    sx={{ marginBottom: '10px', borderRadius: '4px' }}
  />
)

export const TermsConditionsDialogContentSkeleton: React.FC = () => (
  <BoxComponent
    display="flex"
    flexDirection="column"
    testID="Terms conditions dialog skeleton">
    <SkeletonComponent
      variant="rectangular"
      width="100%"
      height={14}
      sx={{ marginBottom: '10px', borderRadius: '4px' }}
    />

    <SkeletonComponent
      variant="rectangular"
      width="100%"
      height={14}
      sx={{ marginBottom: '10px', borderRadius: '4px' }}
    />

    <SkeletonComponent
      variant="rectangular"
      width="100%"
      height={14}
      sx={{ marginBottom: '10px', borderRadius: '4px' }}
    />

    <SkeletonComponent
      variant="rectangular"
      width="100%"
      height={14}
      sx={{ marginBottom: '10px', borderRadius: '4px' }}
    />
  </BoxComponent>
)
