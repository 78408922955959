import { outlinedInputClasses } from '@codebox-team/web/types'

export const themeOverrides = {
  palette: {
    primary: {
      main: '#042455',
      light: '#2979FF',
    },
    secondary: {
      main: '#375f9b',
    },
    text: {
      primary: '#212121',
      disabled: '#9E9E9E',
      secondary: '#616161',
    },
    error: {
      main: '#D50000',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          minHeight: '40px',
          fontWeight: '500',
          textTransform: 'none',
        },
        contained: {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
        },
        outlined: {
          borderColor: '#BDBDBD',
          ':hover': {
            borderColor: '#BDBDBD',
          },
        },
        containedError: {
          backgroundColor: '#D50000',
        },
        outlinedError: {
          borderColor: '#BDBDBD',
          ':hover': {
            borderColor: '#BDBDBD',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: '#DDDDDD',
          borderRadius: '6px',
          borderWidth: 1,
          fontSize: '14px',
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: 1,
          },
          '::placeholder': {
            color: '#616161',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 12,
  },
} as unknown
