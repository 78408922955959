import React from 'react'

import {
  PostContext,
  initialPostContextValue,
} from '@/application/contexts/Post/PostContext'
import { usePostProviderRules } from '@/presentation/components/Providers/Post/PostProvider.rules'
import { useSelector } from '@codebox-team/redux'
import { selectUserIsAuthenticated } from '@move-share-celebrate/core'

export const PostProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const contextValue = usePostProviderRules()

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  return (
    <PostContext.Provider
      value={isUserAuthenticated ? contextValue : initialPostContextValue}>
      {children}
    </PostContext.Provider>
  )
}
