import React from 'react'

import { getSemanticVersion } from '@/application/utils/PackageVersion/PackageVersionUtil'
import { TextComponent } from '@codebox-team/web/components'

export const PoweredByComponent: React.FC = () => (
  <TextComponent fontSize="12px" color="text.disabled">
    v{getSemanticVersion()} - Powered by Delta
  </TextComponent>
)
