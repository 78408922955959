import React from 'react'

import { useFooterNavigationComponentRules } from '@/presentation/components/Navigation/Footer/FooterNavigationComponent.rules'
import { StyledFooterComponent } from '@/presentation/components/Navigation/Footer/FooterNavigationComponent.styles'
import { IterativeItemComponent } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent'
import { BoxComponent } from '@codebox-team/web/components'

export const FooterNavigationComponent = () => {
  const { footerItems } = useFooterNavigationComponentRules()

  return (
    <StyledFooterComponent data-testid="Footer Component">
      <BoxComponent display="flex" container="true" maxWidth="lg" width="100%">
        {footerItems.map(item => (
          <BoxComponent key={`footer_iterative_icon_${item.text}`} flex="1">
            <IterativeItemComponent {...item} />
          </BoxComponent>
        ))}
      </BoxComponent>
    </StyledFooterComponent>
  )
}
