import { useEffect, useRef } from 'react'

import { useCommunityList } from '@move-share-celebrate/core'

const DEFAULT_FILTER = { page: 1, pageSize: 10 }

export const useCommunityListDialogRules = () => {
  const isInitialized = useRef<boolean>(false)

  const {
    data: { items: accounts, page, totalPages, total },
    handleFetch,
    handleNextPage,
    isWaiting,
  } = useCommunityList()

  const handleFetchNextPage = () => {
    handleNextPage({ ...DEFAULT_FILTER, page: page + 1 })
  }

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true
      handleFetch(DEFAULT_FILTER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { accounts, page, totalPages, total, isWaiting, handleFetchNextPage }
}
