import { StyleSheet } from '@codebox-team/web/utils'

export const ThumbnailWrapper = StyleSheet.create('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
  borderRadius: '12px',

  background:
    'linear-gradient(152.08deg,#4ccde2 6.58%,#2e6eb3 67.19%,#ea2b1d 90.3%)',

  svg: {
    path: {
      fill: theme.palette.common.white,
    },
  },

  ['& img, video, div, span']: {
    width: '44px',
    height: '44px',
    borderRadius: '12px',
    objectFit: 'cover',

    svg: {
      scale: '0.5',
    },
  },
}))
