import React from 'react'

import { useLatestPostsComponentRules } from '@/presentation/components/Data/LatestPosts/LatestPostsComponent.rules'
import { LatestPostsCardComponent } from '@/presentation/components/Surfaces/LatestPosts/Card/LatestPostsCardComponent'
import { LatestPostsCardSkeletonComponent } from '@/presentation/components/Surfaces/LatestPosts/Card/LatestPostsCardComponent.skeleton'

export const LatestPostsComponent: React.FC = () => {
  const { posts, isWaiting } = useLatestPostsComponentRules()

  return isWaiting ? (
    <LatestPostsCardSkeletonComponent />
  ) : (
    <LatestPostsCardComponent posts={posts} />
  )
}
