import { useEffect } from 'react'

import { IUseToast } from '@/presentation/hooks/UseToast/UseToast.types'
import { NotifyUtils } from '@codebox-team/web/utils'

export const useToast = ({
  type,
  actionStatus,
  onDispatchActionStatusCleaner,
}: IUseToast) => {
  const isEnabled = {
    error: actionStatus?.type === 'failure',
    success: actionStatus?.type === 'success',
    info: false,
    warning: false,
  }[type]

  useEffect(() => {
    if (isEnabled) {
      const handleShowToast = () => {
        NotifyUtils?.[type]?.({ content: actionStatus?.message })
        onDispatchActionStatusCleaner && onDispatchActionStatusCleaner()
      }

      handleShowToast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])
}
