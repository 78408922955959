import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { AccountsListComponent } from '@/presentation/components/Surfaces/AccountsList/AccountsListComponent'
import { useCommunityListDialogRules } from '@/presentation/dialogs/CommunityList/CommunityListDialog.rules'
import { ICommunityListDialogProps } from '@/presentation/dialogs/CommunityList/CommunityListDialog.types'
import { ListDialog } from '@/presentation/dialogs/List/ListDialog'
import { BoxComponent } from '@codebox-team/web/components'

export const CommunityListDialog: React.FC<ICommunityListDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { accounts, page, totalPages, total, isWaiting, handleFetchNextPage } =
    useCommunityListDialogRules()

  return (
    <ListDialog
      isOpen={isOpen}
      onClose={onClose}
      list={
        <AccountsListComponent
          accounts={accounts}
          page={page}
          totalPages={totalPages}
          isWaiting={isWaiting}
          onLoadMore={handleFetchNextPage}
          onClose={onClose}
        />
      }
      header={
        <BoxComponent display="flex" gap="4px">
          {translate.t('generic.labels.community')}
          {` (${total})`}
        </BoxComponent>
      }
    />
  )
}
