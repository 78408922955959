import { BoxComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const VideoOverlayWrapper = StyleSheet.create(BoxComponent)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: '#000000a6',
})
