import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import { useSelector } from '@codebox-team/redux'
import { IRouteComponentProps } from '@codebox-team/web/lib/components/Navigation/Route/RouteComponent.types'
import { selectUserIsKeywordManagementAllowed } from '@move-share-celebrate/core'

export const useRoutesConfig = () => {
  const history = useHistory()
  const isKeywordsManagementAvailable = useSelector(
    selectUserIsKeywordManagementAllowed,
  )

  const appPublicRoutes = useMemo(
    (): Array<IRouteComponentProps & { isHidden?: boolean }> => [
      {
        component: React.lazy(() =>
          import(
            '@/application/factories/screens/Login/LoginScreenFactory'
          ).then(({ makeLoginScreenFactory }) => ({
            default: makeLoginScreenFactory,
          })),
        ),
        exact: true,
        isPrivate: false,
        path: '/login',
      },
      {
        component: React.lazy(() =>
          import(
            '@/application/factories/screens/LoginRedirect/LoginRedirectScreenFactory'
          ).then(({ makeLoginRedirectScreenFactory }) => ({
            default: makeLoginRedirectScreenFactory,
          })),
        ),
        exact: true,
        isPrivate: false,
        path: '/redirect',
        redirectPathName: '/',
        location: history.location,
      },
    ],
    [history.location],
  )

  const appPrivateRoutes: Array<IRouteComponentProps & { isHidden?: boolean }> =
    [
      {
        component: React.lazy(() =>
          import('@/application/factories/screens/Feed/FeedScreenFactory').then(
            ({ makeFeedScreenFactory }) => ({
              default: makeFeedScreenFactory,
            }),
          ),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import('@/presentation/screens/Post/Create/PostCreateScreen').then(
            ({ PostCreateScreen }) => ({
              default: PostCreateScreen,
            }),
          ),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/new-post',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import('@/presentation/screens/Post/Edit/PostEditScreen').then(
            ({ PostEditScreen }) => ({
              default: PostEditScreen,
            }),
          ),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/post/:key/edit',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import('@/presentation/screens/Post/Details/PostDetailsScreen').then(
            ({ PostDetailsScreen }) => ({
              default: PostDetailsScreen,
            }),
          ),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/post/:key/details',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import(
            '@/presentation/screens/Notifications/NotificationsScreen'
          ).then(({ NotificationsScreen }) => ({
            default: NotificationsScreen,
          })),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/notifications',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import(
            '@/application/factories/screens/Keywords/KeywordsScreenFactory'
          ).then(({ makeKeywordsScreenFactory }) => ({
            default: makeKeywordsScreenFactory,
          })),
        ),
        isHidden: !isKeywordsManagementAvailable,
        exact: true,
        isPrivate: true,
        path: '/tags',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import('@/presentation/screens/Profile/ProfileScreen').then(
            ({ ProfileScreen }) => ({
              default: ProfileScreen,
            }),
          ),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/profile/:key',
        redirectPathName: '/login',
      },
      {
        component: React.lazy(() =>
          import(
            '@/application/factories/screens/Settings/SettingsScreenFactory'
          ).then(({ makeSettingsScreenFactory }) => ({
            default: makeSettingsScreenFactory,
          })),
        ),
        isHidden: false,
        exact: true,
        isPrivate: true,
        path: '/settings',
        redirectPathName: '/login',
      },
    ]

  return {
    appPrivateRoutes,
    appPublicRoutes,
  }
}
