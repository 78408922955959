import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import ThumbUpIcon from '@/presentation/assets/img/icon/thumb-up-small-icon.svg'
import { IPostInfoBarComponentProps } from '@/presentation/components/Surfaces/PostInfoBar/PostInfoBarComponent.types'
import { BoxComponent, TextComponent } from '@codebox-team/web/components'

export const PostInfoBarComponent: React.FC<IPostInfoBarComponentProps> = ({
  likes,
  comments,
  onNavigate,
  onShowLikes,
}) => (
  <BoxComponent
    display="flex"
    alignItems="center"
    gap="8px"
    color="text.secondary"
    testID="Post info bar component">
    <BoxComponent
      display="flex"
      alignItems="center"
      gap="8px"
      onClick={() => likes && onShowLikes?.()}
      sx={{ cursor: onShowLikes && likes && 'pointer' }}
      testID="Post info bar likes counter">
      <ThumbUpIcon />
      <TextComponent lineHeight="1">{`${likes} ${translate
        .t('generic.labels.likes')
        .toLocaleLowerCase()}`}</TextComponent>
    </BoxComponent>

    <TextComponent lineHeight="1">{' • '}</TextComponent>
    <TextComponent
      lineHeight="1"
      onClick={() => onNavigate?.()}
      sx={{
        cursor: onNavigate && 'pointer',
      }}>{`${comments} ${translate
      .t('generic.labels.comments')
      .toLocaleLowerCase()}`}</TextComponent>
  </BoxComponent>
)
