import { createContext, useContext } from 'react'

import { IPostContext } from '@/application/contexts/Post/PostContext.types'

export const initialPostContextValue: IPostContext = {
  data: undefined,
  isWaiting: false,
  isFailure: false,
  handleFetch: async () => ({} as any),
  localComments: [],
  selectedComment: undefined,
  hasMorePages: false,
  isWaitingComments: false,
  isDeletingComment: false,
  handleInit: () => {},
  handleCommentSubmit: () => {},
  handleCancelUpdateComment: () => {},
  handleInitComments: async () => [] as any,
  handleInitUpdateComment: () => {},
  handleNextPageComment: async () => [] as any,
  handleSubmitCommentUpdate: () => {},
  handleDeleteComment: () => {},
}

export const PostContext = createContext<IPostContext>(initialPostContextValue)

export const usePostContext = () => useContext<IPostContext>(PostContext)
