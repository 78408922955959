import { StyleSheet } from '@codebox-team/web/utils'

export const StyledFooterComponent = StyleSheet.create('footer')(
  ({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px 12px 0px 0px',

    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 3px 3px -2px #00000033, 0px 3px 4px 0px #00000024, 0px 1px 8px 0px #0000001f,',
  }),
)
