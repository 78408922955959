import { useCallback } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

export const useIsPathMatched = () => {
  const location = useLocation()

  const isPathMatched = useCallback(
    (pathToMatch: string) => {
      return !!matchPath(location.pathname, {
        path: pathToMatch,
        exact: true,
        strict: false,
      })
    },
    [location.pathname],
  )

  return { isPathMatched }
}
