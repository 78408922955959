import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FeedSvg from '@/presentation/assets/img/icon/feed-icon.svg'
import GroupsSvg from '@/presentation/assets/img/icon/groups-icon.svg'
import NotificationSvg from '@/presentation/assets/img/icon/notifications-icon.svg'
import ProfileSVG from '@/presentation/assets/img/icon/person-icon.svg'
import SettingSVG from '@/presentation/assets/img/icon/settings-icon.svg'
import { StyledTagIconSvg } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent.styles'
import { IDrawerNavigationComponent } from '@/presentation/components/Navigation/Drawer/DrawerNavigationComponent.types'
import { IIterativeItemComponentProps } from '@/presentation/components/Surfaces/IterativeItem/IterativeItemComponent.types'
import { getFixedNotificationsUnread } from '@/presentation/components/Utils/GetFixedNotificationsUnread/GetFixedNotificationsUnreadUtils'
import { useIsPathMatched } from '@/presentation/hooks/UseIsPathMatched/UseIsPathMatched'
import { useSelector } from '@codebox-team/redux'
import {
  selectUserDetails,
  selectUserIsKeywordManagementAllowed,
} from '@move-share-celebrate/core'

export const useDrawerNavigationComponentRules = ({
  notificationsUnread,
  setIsOpen,
}: IDrawerNavigationComponent) => {
  const history = useHistory()
  const { isPathMatched } = useIsPathMatched()

  const [isCommunityListOpen, setIsCommunityListOpen] = useState<boolean>(false)

  const userDetails = useSelector(selectUserDetails)

  const isKeywordsManagementAvailable = useSelector(
    selectUserIsKeywordManagementAllowed,
  )

  const { pathname } = history.location

  const handleClick = (url: string) => {
    if (url === pathname) {
      setIsOpen(false)
      return
    }

    history.push(url)
  }

  useEffect(() => {
    setIsCommunityListOpen(false)
    setIsOpen(false)
  }, [history.location.pathname, setIsOpen, setIsCommunityListOpen])

  const handleOpenCommunity = () => {
    setIsOpen(false)
    setIsCommunityListOpen(true)
  }

  const handleCloseCommunityDialog = () => setIsCommunityListOpen(false)

  const drawerItems: IIterativeItemComponentProps[] = [
    {
      svgIcon: FeedSvg,
      onClick: () => handleClick('/'),
      text: translate.t('menu.items.feed'),
      isSelected: isPathMatched('/'),
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: 'add',
      text: translate.t('menu.items.new_post'),
      onClick: () => handleClick('/new-post'),
      isSelected: isPathMatched('/new-post'),
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: NotificationSvg,
      text: translate.t('menu.items.notifications'),
      badgeContent: getFixedNotificationsUnread({ notificationsUnread }),
      onClick: () => handleClick('/notifications'),
      isSelected: isPathMatched('/notifications'),
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: ProfileSVG,
      text: translate.t('menu.items.profile'),
      onClick: () => handleClick(`/profile/${userDetails?.key}`),
      isSelected: isPathMatched(`/profile/${userDetails?.key}`),
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: () => <StyledTagIconSvg />,
      text: translate.t('menu.items.tags'),
      onClick: () => handleClick('/tags'),
      isSelected: isPathMatched('/tags'),
      isHidden: !isKeywordsManagementAvailable,
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: GroupsSvg,
      text: translate.t('menu.items.community'),
      onClick: handleOpenCommunity,
      isSelected: false,
      textProps: {
        fontSize: '14px',
      },
    },
    {
      svgIcon: SettingSVG,
      text: translate.t('menu.items.settings'),
      onClick: () => handleClick('/settings'),
      isSelected: isPathMatched('/settings'),
      textProps: {
        fontSize: '14px',
      },
    },
  ]

  return {
    drawerItems: drawerItems.filter(item => !item?.isHidden),
    isCommunityListOpen,
    handleCloseCommunityDialog,
  }
}
