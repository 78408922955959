import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { StyledCardComponent } from '@/presentation/components/Surfaces/LatestPosts/Card/LatestPostsCardComponent.styles'
import { ILatestPostsCardComponent } from '@/presentation/components/Surfaces/LatestPosts/Card/LatestPostsCardComponent.types'
import { LatestPostItemComponent } from '@/presentation/components/Surfaces/LatestPosts/Item/LatestPostItemComponent'
import {
  BoxComponent,
  DividerComponent,
  TextComponent,
} from '@codebox-team/web/components'

export const LatestPostsCardComponent: React.FC<ILatestPostsCardComponent> = ({
  posts,
}) => (
  <StyledCardComponent testID="Last posts card" withoutContentWrapper>
    <BoxComponent
      display="flex"
      width="300px"
      paddingX="16px"
      flexDirection="column">
      <TextComponent
        fontSize="16px"
        color="text.secondary"
        fontWeight="500"
        lineHeight="19px"
        marginBottom="16px">
        {translate.t('post.latest.title')}
      </TextComponent>
      {!posts.length && (
        <TextComponent color="text.disabled">
          {translate.t('post.latest.nothing_to_show')}
        </TextComponent>
      )}
      {posts?.map((post, index) => (
        <React.Fragment key={post.key}>
          <LatestPostItemComponent post={post} />
          {!!posts[index + 1] && (
            <DividerComponent sx={{ width: '100%', marginY: '16px' }} />
          )}
        </React.Fragment>
      ))}
    </BoxComponent>
  </StyledCardComponent>
)
